<script setup lang="ts">
import { useDisplay } from "vuetify";
import { VSnackbar } from "vuetify/components/VSnackbar";
import { mdiClose } from "@mdi/js";
import { useSnackbarStore } from "@/stores/snackbar";

const snackbarStore = useSnackbarStore();
const { mdAndUp } = useDisplay();
const { locale } = useI18n();
</script>

<template>
  <VSnackbar
    v-model="snackbarStore.show"
    :color="snackbarStore.type"
    :location="
      mdAndUp ? (locale === 'en' ? 'top right' : 'top left') : 'bottom'
    "
    :timeout="8000"
  >
    <template #default>
      <span>
        {{ snackbarStore.message }}
      </span>
    </template>
    <template #actions>
      <MBtn
        color="default"
        @click="snackbarStore.show = false"
      >
        <MIcon :icon="mdiClose" />
      </MBtn>
    </template>
  </VSnackbar>
</template>
