<script setup lang="ts">
import { VListGroup } from "vuetify/components/VList";

interface Props {
  value?: any;
}

const props = withDefaults(defineProps<Props>(), {
  value: undefined,
});
</script>

<template>
  <VListGroup
    v-bind="props"
    :fluid="true"
  >
    <template #activator="{ props: slotProps }">
      <slot
        name="activator"
        v-bind="{ props: slotProps }"
      />
    </template>
    <slot />
  </VListGroup>
</template>
