<script setup lang="ts">
import { mdiClose } from "@mdi/js";

const { t } = useI18n();

const hide = ref(false);
</script>

<template>
  <div
    v-if="!hide"
    class="bg-secondary px-4 py-4"
  >
    <div class="items-center justify-between md:flex">
      <div class="mb-3 md:mb-0">
        <p>
          {{ t("this-is-a-demo-versi") }}
        </p>
      </div>
      <span class="block px-2"></span>
      <div>
        <MBtn
          variant="text"
          size="small"
          color="default"
          @click="hide = true"
        >
          <MIcon
            start
            :icon="mdiClose"
          />
          {{ t("dismiss") }}
        </MBtn>
      </div>
    </div>
  </div>
</template>
