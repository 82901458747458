<script lang="ts">
import type { PropType } from "vue";
import MListItem from "@mono/ui/components/MListItem.vue";
import type { TypedRouteLocationRaw } from "@typed-router";

export default defineComponent({
  extends: MListItem,
  props: {
    route: {
      type: Object as PropType<TypedRouteLocationRaw>,
      required: true,
    },
  },
});
</script>

<template>
  <!-- @vue-expect-error -->
  <NuxtLink
    :to="route"
    :disabled="disabled"
    style="text-decoration: none; color: inherit"
  >
    <MListItem v-bind="$props">
      <template
        v-if="$slots['title']"
        #title
      >
        <slot name="title" />
      </template>
      <template
        v-if="$slots['subtitle']"
        #subtitle
      >
        <slot name="subtitle" />
      </template>
      <template
        v-if="$slots['prepend']"
        #prepend
      >
        <slot name="prepend" />
      </template>
      <template
        v-if="$slots['append']"
        #append
      >
        <slot name="append" />
      </template>
      <template
        v-if="$slots['default']"
        #default
      >
        <slot />
      </template>
    </MListItem>
  </NuxtLink>
</template>
